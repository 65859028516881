import { Videos } from '@/components/pages/Videos'
import { graphql } from 'gatsby'

export default Videos

export const query = graphql`
    query PageVideos($skip: Int!, $limit: Int!) {
        contentfulSite {
            siteHeroImage {
                gatsbyImageData(resizingBehavior: FILL)
            }
        }
        allContentfulVideos(
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: updatedAt }
        ) {
            nodes {
                ...VideosInformation
            }
        }
    }
`
