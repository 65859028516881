import * as React from "react"

import { Head } from "@/components/Head"
import { GlobalLayout } from "@/components/GlobalLayout"
import { VideosSection } from "@/components/sections/VideosSection"

import { PageProps } from "gatsby"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"
import { useImage } from "@/hooks/useImage"
import { PageVideosQuery } from "@root/types/graphql-types"
import { PaginationPageContext } from "@/types/PageContext"
import { Pagination } from "@/components/Pagination"

type Props = PageProps<PageVideosQuery, PaginationPageContext>

export const Videos: React.FC<Props> = ({ data, pageContext }) => {
    const pageTitle = "videos"
    const { getImageProps, Image } = useImage()
    return (
        <GlobalLayout Head={<Head title={pageTitle} description={pageTitle} />}>
            <VideosSection
                videos={data.allContentfulVideos?.nodes || undefined}
                videoWrapClass=" u-mb-50px"
                addClass=" u-mb-100px"
                Title={
                    <TitleWithBgImg
                        title={pageTitle.toUpperCase()}
                        BgImage={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData
                                )}
                            />
                        }
                    />
                }
            >
                <Pagination
                    currentPage={pageContext.currentPage}
                    numPages={pageContext.numberOfPages}
                    basePath="/videos"
                    className="p-section__pagination"
                />
            </VideosSection>
        </GlobalLayout>
    )
}
